$body-bg: #f4f6f8;
$primary-text: #22055c;
$primary-bg: #5532c3;
$primary-bg-text: #ddcafa;
$primary-gradient: #b36fff;
$secondary-gradient: #4625bd;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $body-bg;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.gradient {
  background-image: linear-gradient(
    150deg,
    $primary-gradient,
    $secondary-gradient
  );
}

.btn-primary {
  background-color: $primary-bg !important;
  text-transform: none !important;
  font-weight: bold !important;
  color: $primary-bg-text !important;
  border-radius: 20px !important;
  min-height: 47px;
  min-width: 204px !important;
  display: flex !important;
  margin: 20px auto !important;
  font-size: 16px !important;
}

.sweet-alert .btn-primary {
  align-items: center;
  justify-content: center;
}

.primary-text {
  color: $primary-text;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
